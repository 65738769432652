<template>
  <div class="gps-track">
    <div v-bind:class="mobileMenuActive ? 'block' : 'hidden'" @click="closeMenu()" class="fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"></div>
    <div class="flex flex-wrap unit-gps-wrapper">
      <div ref="canvasContainer" class="w-full lg:w-5/6">
        <div class="flex flex-wrap bg-secondary-200 border-t border-b border-secondary-300">
          <div class="w-1/3 lg:w-2/12 text-primary-500 px-2 py-2 text-sm font-bold cursor-pointer text-center transition duration-200 bg-primary-100 hover:bg-primary-200 hover:text-white" v-bind:class="{ 'btn-toggle-active': !displayFloorPlan }" @click.prevent="displayFloorPlan = false">{{ $t("secure_track.outside") }}</div>
          <div class="w-1/3 lg:w-2/12 text-primary-500 px-2 py-2 text-sm font-bold cursor-pointer text-center transition duration-200 bg-primary-100 hover:bg-primary-200 hover:text-white" v-bind:class="{ 'btn-toggle-active': displayFloorPlan }" @click.prevent="displayFloorPlan = true">{{ $t("secure_track.indoor") }}</div>
          <div class="w-1/3 lg:invisible text-gray-500 px-2 py-2 text-sm font-bold cursor-pointer text-center transition duration-200 bg-secondary-300 hover:bg-secondary-400 hover:text-white" @click="mobileMenuActive = true">{{ $t("secure_track.alarm") }}</div>
        </div>
        <div class="map-container" v-show="!displayFloorPlan">
          <GmapMap :center="center" :zoom="zoom" map-type-id="terrain" class="google_map_wrapper" ref="map" :options="{ fullscreenControl: mapFullScreenControl }">
            <GmapInfoWindow
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="
                infoWinOpen = false;
                selected_alarm_id = '';
              "
            ></GmapInfoWindow>

            <GmapCluster :zoom-on-click="true">
              <GmapMarker v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="false" @mouseover="toggleInfoWindow(m, index)" @mouseout="toggleInfoWindow(m, index)" :key="index" :icon="{ url: m.icon }"></GmapMarker>
            </GmapCluster>
            <GmapPolygon v-for="(polygon, i) in polygonPath" :key="'g' + i" :paths="polygon.path" :options="polygonOptions[i]" @paths_changed="updateEditedPolygon($event, i)" @click="editPolygon(i)" ref="polygon" />
            <GmapCircle v-for="(pin, index) in alarmCircles" :key="index" :center="pin.position" :visible="true" :options="pin.options"></GmapCircle>
            <GmapPolyline v-for="p in push_button_paths" :key="p.imei_number" :path="p.path" :options="{ strokeColor: 'red' }" @mouseover="showInfoWindow($event, p.infoText, true)" @mouseout="showInfoWindow($event, p.infoText, false)"></GmapPolyline>
            <GmapPolyline v-for="p in track_alarm_paths" :key="p.imei_number" :path="p.path" :options="{ strokeColor: '#1a8de9' }" @mouseover="showInfoWindow($event, p.infoText, true)" @mouseout="showInfoWindow($event, p.infoText, false)"></GmapPolyline>
          </GmapMap>
        </div>
        <div class="flex flex-col" v-show="displayFloorPlan">
          <div class="border-b border-secondary-300 mb-1 clear-both" v-if="floor_plan_units.length > 0 && selected_indoor_unit != ''">
            <div class="float-left my-2 pr-3">
              <select class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b border-gray-300 transition duration-500 px-1 py-1" v-model="selected_indoor_unit" @change="populateIndoorUnit()">
                <option value="">{{ $t("secure_track.select_business") }}</option>
                <option v-for="u in floor_plan_units" :key="u._id" :value="u.unit_id">{{ u.name }}</option>
              </select>
            </div>
            <div class="float-right my-2 mr-2" v-if="selected_indoor_unit != ''">
              <button
                class="btn-secondary-outline"
                @click.prevent="
                  selected_indoor_unit = '';
                  resetFloorPlan();
                "
              >
                {{ $t("secure_track.back") }}
                <BaseIcon icon="arrow-left" class="ml-1" />
              </button>
            </div>
          </div>
          <div class="flex flex-wrap mt-4" v-if="selected_indoor_unit == ''">
            <div v-for="(v, i) in floor_plan_units" :key="i" class="w-full lg:w-1/3 flex flex-col px-2 py-2 my-1">
              <img
                :src="v.floorPlanImg"
                class="flex-1 border border-secondary-300 cursor-pointer"
                @click.prevent="
                  selected_indoor_unit = v.unit_id;
                  populateIndoorUnit();
                "
              />
              <div
                class="bg-primary-500 text-white text-center cursor-pointer pt-1"
                @click.prevent="
                  selected_indoor_unit = v.unit_id;
                  populateIndoorUnit();
                "
              >
                <span v-if="customers.length > 1">{{ getCustomerNameFromUnitId(v.unit_id) }} - </span>
                {{ v.name }}
              </div>
            </div>
          </div>
          <div v-if="configImg.image" class="relative z-10">
            <div class="absolute right-0 flex flex-col">
              <button class="bg-secondary-300 text-secondary-700 px-2 py-1 border-b border-secondary-400 mt-1 mr-2" @click.prevent="zoomButton(1)"><BaseIcon icon="search-plus" /></button>
              <button class="bg-secondary-300 text-secondary-700 px-2 py-1 border-b border-secondary-400 mr-2" @click.prevent="zoomButton(-1)"><BaseIcon icon="search-minus" /></button>
              <button class="bg-secondary-300 text-secondary-700 px-2 py-1 mr-2" @click.prevent="zoomReset()"><BaseIcon icon="undo" /></button>
            </div>
            <div class="absolute left-0 flex">
              <button class="bg-secondary-300 text-secondary-700 px-2 py-1 mt-1 mr-2" @click.prevent="toggleZoneVisibility()" @mouseover="showToggleZoneTooltip = true" @mouseout="showToggleZoneTooltip = false">
                <BaseIcon v-if="!noAlarmsTagVisible" icon="eye" />
                <BaseIcon v-if="noAlarmsTagVisible" icon="eye-slash" />
              </button>
              <div v-if="showToggleZoneTooltip" class="text-xs text-white bg-secondary-800 opacity-60 px-2 py-2 mt-1 shadow rounded transition-all duration-100">
                <span v-if="!noAlarmsTagVisible">Visa </span><span v-if="noAlarmsTagVisible">{{ $t("secure_track.hide") }} </span>{{ $t("secure_track.all_rf_tag") }}
              </div>
            </div>
          </div>
          <v-stage ref="stage" :config="configStage" @wheel="zoomWheel" @touchmove="zoomTouch" @touchend="zoomTouchEnd">
            <v-layer>
              <v-image :config="configImg" />
              <v-circle ref="circle" v-for="(z, i) in floorPlanZones" :key="'C' + i" :config="z.circle" @mousemove="showLabel($event, z, true)" @mouseout="showLabel($event, z, false)" @touchend="showLabel($event, z, true)" />
              <v-text v-for="(z, i) in floorPlanZones" :key="'A' + i" :config="z.text" @mousemove="showLabel($event, z, true)" @mouseout="showLabel($event, z, false)" @touchend="showLabel($event, z, true)" />
            </v-layer>
            <v-layer>
              <v-group ref="labelGroup" :config="configLabelGroup">
                <v-rect :config="configLabelRect" />
                <v-text ref="labelText" v-for="(t, i) in labelTextList" :key="'A' + i" :config="t" />
              </v-group>
            </v-layer>
          </v-stage>
        </div>
      </div>
      <transition enter-active-class="transition ease-out duration-100" enter-class="transform translate-x-2 opacity-0" enter-to-class="transform translate-x-0 opacity-100" leave-active-class="transition ease-in duration-75" leave-class="transform translate-x-0 opacity-100" leave-to-class="transform translate-x-2 opacity-0">
        <div class="absolute top-0 right-0 min-h-screen w-4/6 md:w-2/8 lg:min-h-full lg:w-1/6 bg-white position-inherit z-30" v-if="mobileMenuActive">
          <div class="flex flex-wrap" v-if="!displayFloorPlan">
            <div class="w-1/2 text-primary-500 px-2 py-2 text-sm font-bold cursor-pointer text-center transition duration-200 bg-primary-100 hover:bg-primary-200 hover:text-white" v-bind:class="{ 'btn-toggle-active': !isEditMode }" @click="editMode(false)">{{ $t("secure_track.alarm") }}</div>
            <div class="w-1/2 text-primary-500 px-2 py-2 text-sm font-bold cursor-pointer text-center transition duration-200 bg-primary-100 hover:bg-primary-200 hover:text-white" v-bind:class="{ 'btn-toggle-active': isEditMode }" @click="editMode(true)">{{ $t("secure_track.geofence") }}</div>
            <AlarmSound ref="audioControl" :audioSrc="require('../../assets/audios/emergency-alarm.mp3')" :alarmSoundLabel="$t('alarm_sound')" :initialMuteState="false" :alarmDuration="180" />
            <AlarmSearch @queryUpdated="handleSearch" />
          </div>
          <!-- Filter alarms for each unit option  -->
          <div class="flex justify-center p-3 border-b-2 border-gray-300">
            <select class="bg-gray-100 rounded w-full text-xs text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-2 pt-1 pb-1 disabled:opacity-50" v-model="selected_unit" @change="changeUnitAlarms()" :disabled="is_name_empty">
              <option value="">{{ $t("secure_track.all_activities") }}</option>
              <option v-for="(u, i) in filtered_units" :key="i" :value="u._id">{{ u.name }}</option>
            </select>
          </div>
          <div class="unit-alarms-list" v-if="!displayFloorPlan && !isEditMode">
            <div class="scroll-container-alarm">
              <div class="alarm-item text-center" v-if="customer_alarms && customer_alarms.length == 0">
                <div class="alarm-name py-3 w-full">{{ $t("secure_track.no_alarm_found") }}</div>
              </div>
              <div v-for="(a, index) in filteredData" :key="index" class="alarm-item hover:bg-primary-50">
                <div @click="focusAlarm(a.imei_number)" class="alarm-box cursor-pointer py-4 px-4" v-bind:class="{ 'bg-red-500 hover:bg-red-500 triggered': a.pushButton, 'bg-primary-50': selected_alarm_id == a._id && !a.pushButton }">
                  <div class="item text-sm text-primary-500 font-bold pb-2">{{ a.unit_name }}</div>
                  <div v-if="customers.length > 1" class="item text-sm pb-2">{{ getCustomerNameFromUnitId(a.parent_id) }}</div>
                  <div class="item text-sm pb-2">{{ getNameFromUnitId(a.parent_id) }}</div>
                  <div class="item text-sm pb-2">{{ a.phone }}</div>
                  <div class="item text-sm pb-3 flex flex-wrap">
                    <span class="mr-2">
                      <BaseIcon v-if="a.batteryBars == 4" icon="battery-full" class="text-accent-500" />
                      <BaseIcon v-else-if="a.batteryBars == 3" icon="battery-three-quarters" class="text-accent-500" />
                      <BaseIcon v-else-if="a.batteryBars == 2" icon="battery-half" class="text-accent-500" />
                      <BaseIcon v-else-if="a.batteryBars == 1" icon="battery-quarter" v-bind:class="a.pushButton ? 'text-white' : 'text-red-500'" />
                      <BaseIcon v-else-if="a.batteryBars == 0" icon="battery-empty" v-bind:class="a.pushButton ? 'text-white' : 'text-red-500'" />
                    </span>
                    <span v-if="a.battery_charging" class="mr-2">
                      <BaseIcon icon="charging-station" v-bind:class="a.pushButton ? 'text-white' : 'text-red-500'" />
                    </span>
                    <span v-if="a.signalBars" class="mr-2">
                      <img v-if="a.signalBars == 4" :src="require('../../assets/icon/signal/signal-4.svg')" class="w-4" />
                      <img v-else-if="a.signalBars == 3" :src="require('../../assets/icon/signal/signal-3.svg')" class="w-4" />
                      <img v-else-if="a.signalBars == 2" :src="require('../../assets/icon/signal/signal-2.svg')" class="w-4" />
                      <img v-else-if="a.signalBars == 1" :src="require('../../assets/icon/signal/signal-1.svg')" class="w-4" />
                    </span>
                    <span v-if="a.accuracyRadius > 0" class="mr-2">
                      <BaseIcon icon="map-marker-alt" class="mr-1" />
                      <span class="text-xs">{{ a.accuracyRadius }}m</span>
                    </span>
                    <span v-if="a.tagFound == true" class="mr-2">
                      <img :src="require('../../assets/icon/rf_signal.svg')" class="w-4" />
                    </span>
                    <span v-if="a.temperature != null && a.type == 'SRT341'" class="mr-2">
                      <BaseIcon icon="thermometer-half" class="mr-1 text-gray-600" />
                      <span class="text-xs">{{ Math.round(a.temperature) }}°C</span>
                    </span>
                    <span v-if="a.temperature != null && a.type == 'SRT341'" class="mr-2 group" v-on:click.stop="showElectCmp(a)">
                      <BaseIcon icon="bolt" class="mr-1 text-gray-600 group-hover:text-primary-500" />
                      <span class="text-xs group-hover:text-primary-500">{{ a.powerConsumed }} kWh</span>
                    </span>
                    <span v-if="a.temperature != null && a.type == 'SRT341' && a.activeInputs.indexOf('inp0') == -1" class="mr-2">
                      <BaseIcon icon="sync-alt" class="mr-1" v-bind:class="a.pushButton ? 'text-white' : 'text-secondary-500'" />
                      <span class="text-xs" v-bind:class="a.pushButton ? 'text-white' : 'text-secondary-500'">Av</span>
                    </span>
                    <span v-if="a.temperature != null && a.type == 'SRT341' && a.activeInputs.indexOf('inp0') > -1" class="mr-2">
                      <BaseIcon icon="sync-alt" spin class="mr-1" v-bind:class="a.pushButton ? 'text-white' : 'text-accent-600'" />
                      <span class="text-xs" v-bind:class="a.pushButton ? 'text-white' : 'text-accent-600'">På</span>
                    </span>
                    <span v-if="a.temperature != null && a.type == 'SRT341' && a.activeInputs.indexOf('inp1') > -1" class="mr-2">
                      <BaseIcon icon="exclamation-triangle" class="mr-1" v-bind:class="a.pushButton ? 'text-white' : 'text-red-500'" />
                      <span class="text-xs" v-bind:class="a.pushButton ? 'text-white' : 'text-red-500'">Larm</span>
                    </span>
                    <span v-if="a.type == 'SRT326'" class="mr-2">
                      <img :src="require('../../assets/icon/location_off.svg')" class="w-4" />
                    </span>
                    <span v-if="new RegExp(/^TELTONIKA/g).test(a.type)" class="mr-2">
                      <BaseIcon icon="gas-pump" class="mr-1" />
                      <span class="text-xs">{{ a.obdOemFuelLevel }} Ltr</span>
                    </span>
                    <span v-if="new RegExp(/^TELTONIKA/g).test(a.type)" class="mr-2">
                      <BaseIcon icon="tachometer-alt" class="mr-1" />
                      <span class=" text-xs">{{ a.totalOdometer }} Km</span>
                    </span>
                  </div>
                  <div class="item text-sm pb-3 flex flex-wrap">
                    <span class="mr-2 p-1 bg-white border rounded h-7">
                      <a :href="generateMapLink('google', a)" target="_blank">
                        <img :src="require('../../assets/icon/google-maps.png')" class="w-4" />
                      </a>
                    </span>

                    <span class="mr-2 p-1 bg-white border rounded h-7" v-if="checkIOS()">
                      <a :href="generateMapLink('apple', a)" target="_blank">
                        <img :src="require('../../assets/icon/apple-map-pin.png')" class="w-4" />
                      </a>
                    </span>
                  </div>
                  <div class="item text-xxs">
                    <span v-if="new RegExp('^SRT').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                    <span v-if="new RegExp('^TWIG').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 * 2.5 }">⬤</span>
                    <span v-if="new RegExp('^EMERIT').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                    <span v-if="new RegExp('^TELTONIKA').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                    {{ a.lastSeen }}
                  </div>
                  <div class="pt-3" v-if="a.temperature != null && a.type == 'SRT341'">
                    <button class="mr-2" v-bind:class="a.pushButton ? 'btn-secondary' : 'btn-red-outline'" v-on:click.stop="turnRelayOffPrompt(a)">
                      OFF
                      <BaseIcon icon="power-off" class="ml-1" />
                    </button>
                    <button class="mr-2" v-bind:class="a.pushButton ? 'btn-secondary' : 'btn-secondary-outline'" v-on:click.stop="showTempThreshold(a)">
                      <BaseIcon icon="temperature-high" class="" />
                    </button>
                  </div>
                  <div class="mt-3 flex flex-wrap text-xs">
                    <router-link v-if="user.role == 'admin'" :to="'/asset-tracker/' + a.imei_number" class="btn-secondary-outline mr-2" target="_blank">
                      <BaseIcon icon="truck" />
                    </router-link>
                    <div v-if="(!a.pushButton && a.speed > 5 && a.lastSeenTime < 1000 * 60 * 30) || a.tracking_active">
                      <button v-bind:class="a.tracking_active ? 'btn-green' : 'btn-secondary-outline'" v-on:click.stop="trackAlarm(a.imei_number, a.unit_name, a.tracking_active, a.tracking_id, index)">{{ $t("secure_track.follow_alarm") }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ValidationObserver ref="formEditGeofence">
            <div class="unit-alarms-list pt-4" v-if="!displayFloorPlan && isEditMode">
              <div v-if="is_name_empty" class="text-center text-xs italic text-red-600 mb-1">
                <span>{{ $t("secure_track.geofence_name") }}</span>
              </div>
              <div v-if="customers.length > 1" class="flex justify-center pb-2 mx-2">
                <select class="bg-gray-100 rounded w-full text-xs text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-2 pt-1 pb-1 disabled:opacity-50" v-model="selected_customer" @change="changeCustomer()" :disabled="is_name_empty">
                  <option v-if="user.role != 'user'" value="">{{ $t("secure_track.all_customers") }}</option>
                  <option v-for="(c, i) in customers" :key="i" :value="c._id">{{ c.name }}</option>
                </select>
              </div>
              <div class="flex justify-center pb-2 mx-2">
                <select class="bg-gray-100 rounded w-full text-xs text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-2 pt-1 pb-1 disabled:opacity-50" v-model="selected_unit" @change="changeUnit()" :disabled="is_name_empty">
                  <option value="">{{ $t("secure_track.all_activities") }}</option>
                  <option v-for="(u, i) in filtered_units" :key="i" :value="u._id">{{ u.name }}</option>
                </select>
              </div>
              <div v-if="selected_unit == '' && geofence_change == null" class="text-center text-xs italic text-red-600">
                <span>{{ $t("secure_track.select_business_to_edit") }}</span>
              </div>
              <div class="text-center text-xs italic mt-2" v-if="selected_unit == ''">
                <button @click.prevent="addGeofencePath('customer')" class="btn-blue mr-1">{{ $t("secure_track.add_public_geofence") }}<BaseIcon class="ml-1" icon="plus" /></button>
                <button @click.prevent="show('modal-geofence-email')" class="btn-secondary-outline mr-1 mt-1">{{ $t("secure_track.notice") }}<BaseIcon class="ml-1" icon="bell" /></button>
                <button @click.prevent="updateGeofenceUnit()" class="btn-green mt-1">{{ $t("user_actions.save") }}<BaseIcon class="ml-1" icon="save" /></button>
              </div>
              <div class="flex flex-wrap justify-center pt-1 pb-2" v-if="selected_unit != ''">
                <button @click.prevent="addGeofencePath('unit')" class="btn-blue px-1 py-1 mr-1 mt-1">{{ $t("user_actions.add") }}<BaseIcon class="ml-1" icon="plus" /></button>
                <button @click.prevent="show('modal-geofence-email')" class="btn-secondary-outline mr-1 px-1 py-1 mt-1">{{ $t("secure_track.notice") }}<BaseIcon class="ml-1" icon="bell" /></button>
                <button @click.prevent="updateGeofenceUnit()" class="btn-green px-1 py-1 mt-1">{{ $t("user_actions.save") }}<BaseIcon class="ml-1" icon="save" /></button>
              </div>
              <div v-if="geofence_change != null" class="text-center text-xs italic mt-2">
                <span v-bind:class="{ 'text-green-600': geofence_change == false, 'text-red-600': geofence_change == true }">{{ geofence_change_msg }}</span>
              </div>
              <div class="pt-2 br-btm"></div>
              <div class="scroll-container">
                <div class="alarm-item text-center" v-if="polygonPath && polygonPath.length == 0">
                  <div class="alarm-name py-3 w-full" v-if="selected_unit == ''">{{ $t("secure_track.no_public_geofence") }}</div>
                  <div class="alarm-name py-3 w-full" v-if="selected_unit != ''">{{ $t("secure_track.no_geofence_for_business") }}</div>
                </div>
                <div v-for="(p, index) in polygonPath" :key="index" class="alarm-item">
                  <div @click="focusGeofence(p.path, index)" class="alarm-box cursor-pointer py-2 px-2 group" v-bind:class="selectedPolygon == index ? 'bg-primary-400 text-white' : 'hover:bg-primary-200'">
                    <ValidationProvider :name="`Geofence namn- ${index + 1}`" rules="required" v-slot="{ classes, errors }">
                      <div class="input-validate" :class="classes">
                        <input class="bg-gray-100 rounded w-full text-xs text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-2 pt-1 pb-1 disabled:opacity-50 mb-2" v-model="p.name" placeholder="Geofence namn" :ref="'geofenceName' + index" @blur="checkGeofenceIfModified()" />
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                    <div v-if="selected_unit == ''" class="geofence-unit text-xs mb-1 group-hover:text-white" v-bind:class="{ 'text-white': selectedPolygon == index }">{{ $t("secure_track.all_activities") }}</div>
                    <div v-if="selected_unit != ''" class="geofence-unit text-xs mb-1 group-hover:text-white" v-bind:class="{ 'text-white': selectedPolygon == index }">{{ getNameFromUnit_id(selected_unit) }}</div>
                    <div class="clearfix mt-2">
                      <button v-on:click.stop="showSingleGeofence('single-geofence-email', index)" class="btn-secondary-outline bg-white float-left py-1 px-1">{{ $t("email") }}<BaseIcon class="ml-1" icon="envelope" /></button>
                      <button v-on:click.stop="removeGeofencePath(index)" class="btn-red-outline bg-white float-right py-1 px-1">{{ $t("user_actions.remove") }}<BaseIcon class="ml-1" icon="trash" /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ValidationObserver>
          <div class="flex flex-wrap bg-secondary-200 border border-secondary-300" v-if="displayFloorPlan">
            <div class="w-1/2 text-primary-500 px-2 py-2 text-sm font-bold cursor-pointer text-center transition duration-200 bg-primary-100 hover:bg-primary-200 hover:text-white btn-toggle-active">Alarm</div>
          </div>
          <div class="unit-alarms-list" v-if="displayFloorPlan">
            <div class="scroll-container-alarm">
              <div class="alarm-item text-center" v-if="unit_indoor_alarms && unit_indoor_alarms.length == 0">
                <div class="alarm-name py-3 w-full text-sm">{{ $t("secure_track.no_alarm_found") }}</div>
              </div>
              <div v-for="(a, index) in unit_indoor_alarms" :key="index" class="alarm-item hover:bg-primary-50">
                <div @click="focusIndoorAlarm(a)" class="alarm-box cursor-pointer py-4 px-4" v-bind:class="{ 'bg-red-500 hover:bg-red-500 triggered': a.pushButton }">
                  <div class="item text-sm text-primary-500 font-bold pb-2">{{ a.unit_name }}</div>
                  <div v-if="customers.length > 1" class="item text-sm pb-2">{{ getCustomerNameFromUnitId(a.parent_id) }}</div>
                  <div class="item text-sm pb-2">{{ getNameFromUnitId(a.parent_id) }}</div>
                  <div class="item text-sm pb-2">{{ a.phone }}</div>
                  <div class="item text-sm pb-3">{{ a.tag_name }}</div>
                  <div class="item text-xxs">
                    <span v-if="new RegExp('^SRT').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                    <span v-if="new RegExp('^TWIG').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 * 2.5 }">⬤</span>
                    <span v-if="new RegExp('^TELTONIKA').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                    {{ a.lastSeen }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <modal name="modal-geofence-email" class="modal-inner modal-geofence-email" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="200" height="auto" :scrollable="true" @before-close="resetGeofenceAlertForm()">
      <span class="close-button" @click="hide('modal-geofence-email')"><BaseIcon icon="times-circle" class="text-white"/></span>
      <div class="modal-header text-lg px-6">
        <span v-if="selected_unit == ''" class="text-white font-serif">{{ $t("secure_track.general_geofence") }}</span>
        <span v-if="selected_unit != ''" class="text-white font-serif"> {{ getNameFromUnit_id(selected_unit) }} -{{ $t("secure_track.geofence_notice") }} </span>
      </div>
      <ValidationObserver ref="formGeofenceEmail">
        <div class="modal-body">
          <div class="flex flex-wrap">
            <div class="w-full">
              <BaseInput v-model="geofenceEmails" type="text" field_name="Email" placeholder="peter@company.com, vincent@company.com" rules="" />
              <p class="text-small-italic">{{ $t("secure_track.enter_multiple") }}</p>
            </div>

            <div class="w-full border-b border-secondary-400 my-5"></div>
            <div class="w-full flex flex-col">
              <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">
                SMS
              </div>
              <div class="w-full lg:w-1/3 flex flex-col mt-1 py-2" v-for="(n, i) in geofenceSMS" :key="i">
                <div class="flex flex-wrap">
                  <div class="w-9/12">
                    <ValidationProvider :name="'SMS Nummer ' + (i + 1)" rules="required" v-slot="{ classes, errors }">
                      <div class="input-validate" :class="classes">
                        <input-mask type="text" v-model="n.msisdn" mask="46999999999" placeholder="Telefonnummer" class="w-full bg-gray-100 rounded text-sm text-gray-900 focus:outline-none border-b border-gray-300 transition duration-500 px-3 py-1"></input-mask>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="w-3/12">
                    <div class="clear-both">
                      <button class="float-right text-red-500 py-1 mr-4" @click.prevent="removeSMSInput(i)"><BaseIcon icon="minus-circle" /></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full">
                <button class="btn-blue-outline mt-2" @click.prevent="addSMSInput()">{{ $t("secure_track.add") }} <BaseIcon icon="plus" class="ml-1" /></button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="footer-container clearfix">
            <button class="btn-red-outline px-3 py-2 float-left" @click="hide('modal-geofence-email')">{{ $t("user_actions.cancel") }} <BaseIcon icon="times-circle" class="ml-1" /></button>
            <button class="btn-green px-3 py-2 float-right" @click="saveGeofenceEmail()">{{ $t("user_actions.save") }} <BaseIcon icon="save" class="ml-1" /></button>
          </div>
        </div>
      </ValidationObserver>
    </modal>

    <modal name="single-geofence-email" class="modal-inner single-geofence-email" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="200" height="auto" :scrollable="true">
      <span class="close-button" @click="hide('single-geofence-email')"><BaseIcon icon="times-circle" class="text-white"/></span>
      <div class="modal-header text-lg bg-primary-50 font-serif">
        <span
          >{{ $t("secure_track.specific_geofence") }} <b v-if="activeEmailModalIndex != null && polygonPath.length > 0">- {{ polygonPath[activeEmailModalIndex].name }}</b></span
        >
      </div>
      <ValidationObserver ref="formGeofenceSingleEmail">
        <div class="modal-body">
          <div class="flex flex-wrap">
            <div class="w-full">
              <BaseInput v-model="singleGeofeneEmails" type="text" field_name="Email" placeholder="peter@company.com, vincent@company.com" rules="" />
              <p class="text-small-italic">{{ $t("secure_track.enter_multiple") }}</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="footer-container clearfix">
            <button class="btn-red-outline px-3 py-2 float-left" @click="hide('single-geofence-email')">{{ $t("user_actions.cancel") }}<BaseIcon icon="times-circle" class="ml-1" /></button>
            <button class="btn-blue px-3 py-2 float-right" @click="updateSingleGeofenceEmail()">{{ $t("user_actions.update") }}<BaseIcon icon="envelope-open" class="ml-1" /></button>
          </div>
        </div>
      </ValidationObserver>
    </modal>

    <AlarmGPSInfo :alarmInfo="infoSRT326" />
    <AlarmSoundSettings />
    <TempAlarmThreshold :alarmInfo="infoTempThreshold" @setHighTemperature="sendHighTempThresholdCmd" />
    <ElectCmpUpdate :alarmInfo="infoElectCmp" @setOperatingTime="getGPSCustomer()" />
  </div>
</template>

<script>
const MODAL_WIDTH = 700;

import AlarmSoundSettings from "@/components/modal/alarm_sound_settings";
import AlarmGPSInfo from "@/components/modal/alarm_gps_info";
import TempAlarmThreshold from "@/components/modal/temp_alarm_threshold";
import ElectCmpUpdate from "@/components/modal/elect_cmp_update";
import { gmapApi } from "vue2-google-maps";
import "@googlemaps/markerclustererplus";
import { io } from "socket.io-client";
import Konva from "konva";
import AlarmSearch from "@/components/securetrack/alarm_search";
import AlarmSound from "@/components/securetrack/alarm_sound";

Konva.hitOnDragEnabled = true;

export default {
  name: "GPSCustomerTrack",
  title() {
    return `SecurTrack | SecurCloud`;
  },
  components: {
    AlarmGPSInfo,
    TempAlarmThreshold,
    ElectCmpUpdate,
    AlarmSoundSettings,
    AlarmSearch,
    AlarmSound,
  },
  data() {
    return {
      customer_id: this.$route.params.id,
      zoom: this.$store.state.mapZoom,
      center: this.$store.state.mapCenter,
      isMobile: false,
      mobileMenuActive: false,
      mapFullScreenControl: true,
      customer: null,
      customers: [],
      units: [],
      customer_alarms: null,
      all_unit_alarms: [],
      gps_alarms: null,
      markers: [],
      polygonOptions: [],
      polygonPath: [],
      mvcPaths: null,
      refreshIntervalRef: null,
      refreshInterval: 30000, // every 30s
      selected_alarm_id: "",
      firstLoad: true,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: { content: "", pixelOffset: { width: 0, height: -35 } },
      alarmCircles: [],
      track_alarm: [],
      push_button_paths: [],
      track_alarm_paths: [],
      // geofence
      isEditMode: false,
      selected_customer: "",
      selected_unit: "",
      filtered_units: [],
      geofence_last_saved: {
        units: [],
        customer: [],
      },
      geofenceEmails: "",
      geofenceSMS: [],
      singleGeofeneEmails: "",
      activeEmailModalIndex: null,
      geofence_change: null,
      geofence_change_msg: "",
      selectedPolygon: null,
      is_name_empty: false,
      infoSRT326: null,
      infoTempThreshold: null,
      infoElectCmp: null,
      // floor plan
      displayFloorPlan: false,
      selected_indoor_unit: "",
      floor_plan_units: [],
      configStage: {
        width: 1,
        height: 1,
        scaleX: 1,
        scaleY: 1,
        draggable: true,
      },
      configLabelGroup: {
        x: 0,
        y: 0,
        opacity: 1,
        visible: false,
        scaleX: 1,
        scaleY: 1,
      },
      configLabelRect: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        fill: "black",
        pointerDirection: "none",
        lineJoin: "round",
        shadowColor: "black",
        shadowBlur: 10,
        shadowOffsetX: 10,
        shadowOffsetY: 10,
        shadowOpacity: 0.5,
        cornerRadius: 4,
      },
      labelTextList: [],
      imageSize: { width: 1, height: 1 },
      configImg: {
        x: 0,
        y: 0,
        image: null,
        width: 1,
        height: 1,
      },
      lastCenter: null,
      lastDist: 0,
      indoor_alarms: [],
      unit_indoor_alarms: [],
      floorPlanZones: [],
      noAlarmsTagVisible: false,
      showToggleZoneTooltip: false,
      animationRef: [],
      // command socket
      socket: null,
      filterType: "unitName",
      searchQuery: "",
      isAudioEnabled: this.$store.state.playAlarmSound,
      isAudioPlaying: false,
      isMute: false,
      audio: null,
      audioPlayInterval: null,
      remainingDuration: 0,
    };
  },
  methods: {
    editMode(val) {
      if (this.isEditMode == true && this.geofence_change == true) {
        this.$modal.show("dialog", {
          title: `${this.$t("confirmation_modal.title")}`,
          text: `<p style="text-align: center;">${this.$t("confirmation_modal.message_1")}</p>`,
          buttons: [
            {
              title: `<div class="bg-accent-500 text-white text-sm font-sans py-2">${this.$t("confirmation_modal.continue_editing")}</div>`,
              handler: () => {
                this.$modal.hide("dialog");
              },
            },
            {
              title: `<div class="bg-red-500 text-white text-sm font-sans py-2">${this.$t("confirmation_modal.exit")}</div>`,
              handler: () => {
                this.switchTab(val);
                this.$modal.hide("dialog");
              },
            },
          ],
        });
      } else this.switchTab(val);
    },

    switchTab(val) {
      this.isEditMode = val;

      if (this.customers.length > 0) this.selected_customer = this.customers[0]._id;
      else this.selected_customer = "";

      this.selected_unit = "";
      this.selectedPolygon = null;
      this.geofence_change = null;
      this.geofence_change_msg = "";
      this.activeEmailModalIndex = null;
      this.is_name_empty = false;

      if (this.isEditMode == false) {
        this.getGPSCustomer();
        this.polygonPath = [];
      } else {
        if (this.user.role == "user" && this.units.length > 0) this.selected_unit = this.units[0]._id;
        this.changeCustomer();
      }
    },

    changeCustomer() {
      this.selectedPolygon = null;

      if (this.selected_customer != "") {
        this.selected_unit = "";
        this.filtered_units = _.filter(this.units, { parent_id: this.selected_customer });
        let i = _.findIndex(this.customers, { _id: this.selected_customer });
        this.polygonPath = this.customers[i].geofence_polygon;
      } else {
        this.filtered_units = this.units;
        this.polygonPath = [];
      }

      this.polygonOptions = [];
      //eslint-disable-next-line
      this.polygonPath.forEach((p) => {
        this.polygonOptions.push({
          strokeColor: "#1A8DE9",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#1A8DE9",
          fillOpacity: 0.4,
          editable: false,
          draggable: false,
        });
      });

      requestAnimationFrame(() => {
        this.$refs.formEditGeofence.reset();
      });
    },

    changeUnit() {
      this.selectedPolygon = null;

      if (this.selected_unit != "") {
        let i = _.findIndex(this.units, { _id: this.selected_unit });
        this.polygonPath = this.units[i].geofence_polygon;
      } else if (this.selected_customer != "") {
        this.filtered_units = _.filter(this.units, { parent_id: this.selected_customer });
        let i = _.findIndex(this.customers, { _id: this.selected_customer });
        this.polygonPath = this.customers[i].geofence_polygon;
      }

      this.polygonOptions = [];
      //eslint-disable-next-line
      this.polygonPath.forEach((p) => {
        this.polygonOptions.push({
          strokeColor: "#1A8DE9",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#1A8DE9",
          fillOpacity: 0.4,
          editable: false,
          draggable: false,
        });
      });

      requestAnimationFrame(() => {
        this.$refs.formEditGeofence.reset();
      });
    },

    addGeofencePath(type) {
      var vm = this;
      // add user editable polygon path
      var bounds = this.$refs.map.$mapObject.getBounds();
      var northEast = bounds.getNorthEast();
      var southWest = bounds.getSouthWest();
      var center = bounds.getCenter();
      var degree = this.polygonPath.length + 1;
      var f = Math.pow(0.66, degree);

      // Draw a triangle. Use f to control the size of the triangle.
      var path = [
        { lng: center.lng(), lat: (1 - f) * center.lat() + f * northEast.lat() },
        { lng: (1 - f) * center.lng() + f * southWest.lng(), lat: (1 - f) * center.lat() + f * southWest.lat() },
        { lng: (1 - f) * center.lng() + f * northEast.lng(), lat: (1 - f) * center.lat() + f * southWest.lat() },
      ];

      this.polygonPath.unshift({
        name: "",
        path: path,
        email: "",
        geofence_type: type,
      });

      this.polygonOptions.forEach((o) => {
        o.editable = false;
        o.draggable = false;
      });
      this.polygonOptions.unshift({
        strokeColor: "#1A8DE9",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#1A8DE9",
        fillOpacity: 0.4,
        editable: true,
        draggable: true,
      });

      this.selectedPolygon = 0;

      this.checkGeofenceIfModified();

      this.$nextTick().then(() => {
        vm.$refs["geofenceName0"][0].focus();
      });
    },

    updateEditedPolygon(mvcPaths, i) {
      let path = [];

      for (let i = 0; i < mvcPaths.getLength(); i++) {
        for (let j = 0; j < mvcPaths.getAt(i).getLength(); j++) {
          let point = mvcPaths.getAt(i).getAt(j);
          path.push({ lat: point.lat(), lng: point.lng() });
        }
      }

      this.polygonPath[i].path = path;

      this.checkGeofenceIfModified();
    },

    removeGeofencePath(index) {
      this.polygonPath.splice(index, 1);
      this.polygonOptions.splice(index, 1);
      this.selectedPolygon = null;
      requestAnimationFrame(() => {
        this.$refs.formEditGeofence.reset();
        this.checkGeofenceIfModified();
      });
    },

    checkGeofenceIfModified() {
      let modified = false;

      // check each unit for any edit
      for (let i = 0; i < this.units.length; i++) {
        let unit = this.units[i];
        let last_saved_unit = _.find(this.geofence_last_saved.units, { _id: unit._id });

        let isNotModified = _.isEqual(unit.geofence_polygon, last_saved_unit.geofence_polygon);
        if (isNotModified == false) {
          modified = true;
          break;
        }
      }
      // check for customer
      for (let i = 0; i < this.customers.length; i++) {
        let customer = this.customers[i];
        let last_saved_customer = _.find(this.geofence_last_saved.customer, { _id: customer._id });

        let isNotModified = _.isEqual(customer.geofence_polygon, last_saved_customer.geofence_polygon);
        if (isNotModified == false) {
          modified = true;
          break;
        }
      }

      if (modified == true) {
        this.geofence_change = true;
        this.geofence_change_msg = "Ändringarna har inte sparats";
      } else {
        this.geofence_change = null;
        this.geofence_change_msg = "";
      }

      this.checkGeofenceNameForm();
    },

    checkGeofenceNameForm() {
      this.$refs.formEditGeofence.validate().then((success) => {
        if (!success) this.is_name_empty = true;
        else this.is_name_empty = false;
      });
    },

    refreshGPSData() {
      this.refreshIntervalRef = setInterval(() => {
        if (this.customer_alarms.length > 0 && !this.isEditMode) this.getGPSCustomer();
      }, this.refreshInterval);
    },

    getGPSCustomer() {
      var vm = this;

      let customer_id = "";
      if (this.user.role != "superuser" && this.user.role != "user" && this.id != "-") customer_id = this.customer_id;

      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/gps/customer/${customer_id}`)
        .then((response) => {
          this.customers = response.data.customers;

          this.units = response.data.units;
          this.filtered_units = this.units;

          this.gps_alarms = response.data.gps_alarms;
          this.track_alarm = response.data.track_alarm;
          this.all_unit_alarms = response.data.customer_alarms;
          this.floor_plan_units = _.filter(this.units, (o) => {
            return o.floorPlanImg != null;
          });

          if (this.customers.length == 1) {
            this.selected_customer = this.customers[0]._id;
            this.setPageTitle(`${this.customers[0].name} - SecurTrack`, "gpsCustomer");
          } else this.setPageTitle(`SecurTrack`, "gpsCustomer");

          // remove alarms that are not in gps db (as they are still not active and registered completely in system)
          var larms = [];
          var push_btn_larms = [];
          var indoor_alarms = [];

          for (let i = 0; i < response.data.customer_alarms.length; i++) {
            let el = response.data.customer_alarms[i];

            el.tracking_active = false;
            el.tracking_id = null;

            let a = _.find(this.gps_alarms, { imei_number: el.imei_number });

            if (a) {
              let lastSeenObj = this.getAlarmLastSeenTime(a);
              el.lastSeen = lastSeenObj.lastSeen;
              el.lastSeenTime = lastSeenObj.lastSeenTime;

              el.speed = a.alarm_speed;
              if (new RegExp(/^SRT/g).test(el.type)) el.speed = (a.alarm_speed * 1.852).toFixed(2);

              // Teltonika vehical speed
              if (new RegExp(/^TELTONIKA/g).test(el.type)) el.speed = a.teltonika_other_data.vehicle_speed || 0;

              // battery
              el.batteryPercent = a.battery_percent;

              // Teltonika battery percantage
              if (new RegExp(/^TELTONIKA/g).test(el.type)) el.batteryPercent = Math.min(Math.floor(a.battery_voltage * 100 - 323), 100);

              let batteryBars = 0;
              if (el.batteryPercent > 80) batteryBars = 4;
              else if (el.batteryPercent > 50) batteryBars = 3;
              else if (el.batteryPercent > 25) batteryBars = 2;
              else if (el.batteryPercent > 10) batteryBars = 1;
              el.batteryBars = batteryBars;

              // gsm signal
              let signalPercent = 0;
              if (new RegExp(/^SRT/g).test(el.type)) {
                if (a.gsm_signal <= 31) signalPercent = Math.ceil(3.225 * a.gsm_signal);
                let signalBars = 0;
                if (signalPercent > 80) signalBars = 4;
                else if (signalPercent > 50) signalBars = 3;
                else if (signalPercent > 25) signalBars = 2;
                else if (signalPercent > 0) signalBars = 1;
                el.signalBars = signalBars;
              } else if (new RegExp(/^TELTONIKA/g).test(el.type)) {
                //gsm signal for Teltonika
                let signalBars = a.gsm_signal - 1 > 0 ? a.gsm_signal - 1 : 0;
                el.signalBars = signalBars;
              }

              // temperature
              el.temperature = a.temperature;

              // input states
              el.activeInputs = a.active_inputs;

              // power consumption
              el.powerConsumed = a.other_data && a.other_data.op_time ? Math.round((parseInt(a.other_data.op_time.v) + parseInt(a.other_data.op_time.c)) * 0.42) : 0;

              // high temp
              el.highTemp = a.other_data ? a.other_data.high_tmp : 0;

              // tag
              el.tagFound = false;
              if (a.tag_id && a.tag_timestamp && new Date().getTime() - new Date(a.tag_timestamp).getTime() < 300000) el.tagFound = true;

              //Teltonika Odometer in Km
              if (new RegExp(/^TELTONIKA/g).test(el.type)) el.totalOdometer = a.teltonika_other_data.obd_oem_total_mileage;

              //Teltonika Fuel Level in Ltr
              if (new RegExp(/^TELTONIKA/g).test(el.type)) {
                el.obdOemFuelLevel = a.teltonika_other_data.odb_oem_fuel_level * 0.1 || 0;
              }
              // charging
              el.battery_charging = a.battery_charging;
              // accuracy radius
              el.accuracyRadius = 0;
              if (new RegExp(/^SRT/g).test(el.type)) {
                // satellites, hdop
                el.satellites = a.satellites;
                el.hdop = a.hdop;
                // accuracy radius
                let userRangeError = 70;
                if (el.satellites >= 5) userRangeError = 30;

                if (el.hdop >= 0) el.accuracyRadius = Math.ceil(el.hdop * userRangeError);
              } else if (new RegExp(/^TWIG/g).test(el.type)) el.accuracyRadius = a.hdop;
              // twig devices
              else if (new RegExp(/^TELTONIKA/g).test(el.type)) {
                // TELTONIKA devices
                // satellites, hdop
                el.satellites = a.satellites;
                el.hdop = a.hdop * 0.1;
                // accuracy radius
                let userRangeError = 70;
                if (el.satellites >= 5) userRangeError = 30;

                if (el.hdop >= 0) el.accuracyRadius = Math.ceil(el.hdop * userRangeError);
              }
              el.pushButton = false;
              if (a.lastPushButtonTimestamp && new Date().getTime() - a.lastPushButtonTimestamp < 1800000) {
                el.pushButton = true;
                el.lastPushButtonTimestamp = a.lastPushButtonTimestamp;
                push_btn_larms.push(el);
                if (vm.$store.state.playingAlarmId !== a.imei_number) {
                  vm.$store.commit("SET_ALARM_ID", a.imei_number);
                  vm.$refs.audioControl.playAudio();
                }
              } else larms.push(el);

              // populate indoor alarms
              if (a.tag_id && a.tag_timestamp && new Date().getTime() - new Date(a.tag_timestamp).getTime() < 1800000) {
                let tagg = _.find(this.all_unit_alarms, function(o) {
                  if (/^SRT/.test(el.type)) return o.modelnumber == a.tag_id.slice(2);
                  else if (/^TWIG/.test(el.type)) return o.modelnumber == a.tag_id;
                  else return false;
                });
                if (tagg) {
                  el.tag_id = tagg.modelnumber;
                  el.tag_name = tagg.unit_name;
                  el.tag_timestamp = a.tag_timestamp;
                  indoor_alarms.push(el);
                }
              }
            }
          }

          push_btn_larms = push_btn_larms.sort(function(a, b) {
            return b.lastPushButtonTimestamp - a.lastPushButtonTimestamp;
          });
          larms = _.sortBy(larms, "parent_id");
          this.customer_alarms = [...push_btn_larms, ...larms];
          this.indoor_alarms = indoor_alarms;

          this.alarmCircles = [];
          this.push_button_paths = [];
          this.track_alarm_paths = [];
          this.markers = [];

          this.gps_alarms.forEach((el) => {
            let alarm_index = _.findIndex(this.customer_alarms, { imei_number: el.imei_number });

            if (alarm_index >= 0 && this.customer_alarms[alarm_index].type != "SRT326" && el.gps_pos && el.gps_pos.lat != null) {
              let larm = this.customer_alarms[alarm_index];
              // Set icons for alarms in securTrack
              // base version of icon is SRT405/i

              let icon = require("@/assets/icon/new_small_map_marker.png");
              if (larm.type == "SRT430") icon = require("@/assets/icon/small_SRT430.png");
              else if (larm.type == "SRT341") icon = require("@/assets/icon/srt341.png");
              else if (larm.type == "SRT406") icon = require("@/assets/icon/small_SRT406.png");
              else if (larm.type == "SRT406i") icon = require("@/assets/icon/small_SRT406.png");
              else if (new RegExp(/^TELTONIKA/g).test(larm.type)) icon = require("@/assets/icon/car.png");
              else if (/^TWIG/.test(larm.type)) {
                icon = require("@/assets/icon/twig_map_marker.png");

                if (larm.type == "TWIG One EX") icon = require("@/assets/icon/twig_one_ex.png");
                else if (/^TWIG One/.test(larm.type)) icon = require("@/assets/icon/twig_one.png");
              }
              else if (/^EMERIT/.test(larm.type)) {
                icon = require("@/assets/icon/emerit_watch.png");

                if (larm.type == "EMERIT e-B10") icon = require("@/assets/icon/smartphone.png");
              }

              let markerObj = {
                icon: icon,
                position: { lat: el.gps_pos.lat ? el.gps_pos.lat : 0, lng: el.gps_pos.lng ? el.gps_pos.lng : 0 },
                infoText: `${this.$t("map_info_popup.alarm_name")}: <b>${larm.unit_name}</b><br>
                        ${this.$t("map_info_popup.gsm_signal_strength")}: <b>${this.getGSMSignalPercent(el.gsm_signal, larm.type)}</b><br>
                        ${this.$t("map_info_popup.battery_percentage")}: <b>${larm.batteryPercent} %</b><br>
                        ${this.$t("map_info_popup.speed")}: <b>${larm.speed} km/hr</b><br>
                        Lat: <b>${el.gps_pos.lat ? el.gps_pos.lat.toFixed(3) : "-"}</b>, Lng: <b>${el.gps_pos.lng ? el.gps_pos.lng.toFixed(3) : "-"}</b>
                        `,
                alarmId: larm._id,
                imei: larm.imei_number,
              };

              if (el.temperature != null && larm.unit_name.toLowerCase() != "srt406") markerObj.infoText += `<br>Temperatur: <b>${el.temperature} °C</b>`;

              this.markers.push(markerObj);

              // alarm trigger circle
              if (el.lastPushButtonTimestamp && new Date().getTime() - el.lastPushButtonTimestamp < 1800000) {
                this.alarmCircles.push({
                  position: { lat: el.gps_pos.lat, lng: el.gps_pos.lng },
                  imei_number: el.imei_number,
                  options: {
                    fillColor: "#FF0000",
                    fillOpacity: 0.5,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    radius: 50,
                  },
                });
                // push button path
                let push_button_path = _.find(this.track_alarm, { _id: el.lastPushButtonTrackID });
                if (push_button_path) {
                  let path = _.map(push_button_path.gps_path, "position");
                  this.push_button_paths.push({
                    path: path,
                    imei_number: el.imei_number,
                    infoText: `<b>Push-Button spårning - ${larm.unit_name}</b>`,
                  });
                }
              } else if (larm.accuracyRadius > 0 && larm.type != "SRT326") {
                // accuracy circle
                this.alarmCircles.push({
                  position: { lat: el.gps_pos.lat, lng: el.gps_pos.lng },
                  imei_number: larm.imei_number,
                  options: {
                    fillColor: "#ffce73",
                    fillOpacity: 0.2,
                    // strokeColor: '#ffc250',
                    strokeOpacity: 0,
                    strokeWeight: 0,
                    radius: larm.accuracyRadius,
                  },
                });
              }

              if (el.alarm_track_user)
                el.alarm_track_user.forEach((track_path) => {
                  if (new Date().getTime() - new Date(track_path.timestamp).getTime() < 1800000) {
                    let track_alarm = _.find(this.track_alarm, { _id: track_path.tracking_id, user_name: this.user.username });

                    if (track_alarm) {
                      let path = _.map(track_alarm.gps_path, "position");
                      this.track_alarm_paths.push({
                        path: path,
                        imei_number: track_path.imei_number,
                        infoText: `<b>Spårning - ${larm.unit_name}</b>`,
                      });

                      this.customer_alarms[alarm_index].tracking_active = true;
                      this.customer_alarms[alarm_index].tracking_id = track_path.tracking_id;
                    }
                  }
                });
            }
          });

          this.geofence_last_saved.units = _.cloneDeep(this.units);
          this.geofence_last_saved.customer = _.cloneDeep(this.customers);

          if (this.firstLoad) {
            if (this.selected_alarm_id) {
              this.$nextTick().then(() => {
                let a = _.find(this.customer_alarms, { _id: vm.selected_alarm_id });
                if (a) vm.focusAlarm(a.imei_number);
              });
            } else {
              this.generateBounds();
            }

            this.firstLoad = false;
          }

          if (this.selected_indoor_unit != "") {
            let unit = _.find(this.units, { unit_id: this.selected_indoor_unit });
            if (unit && unit.floorPlanImg) this.updatefloorPlanData(unit);
          }

          if (document.hasFocus()) this.handleSuccess("Plats uppdaterad", "top-right", 1000);
        })
        .catch((error) => {
          this.handleError(error, "Ett problem uppstod när plats uppdaterades");
        });
    },

    // relay
    turnRelayOffPrompt(alarm) {
      this.$modal.show("dialog", {
        title: `<span class="text-red-500">Ström OFF, ${alarm.unit_name}?</span>`,
        buttons: [
          {
            title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">Nej</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">Ja</div>',
            handler: () => {
              this.sendRelayOffCommand(alarm.imei_number, alarm.type);
            },
          },
        ],
      });
    },

    sendRelayOffCommand(imei, deviceType) {
      let relayCommand = "#C120*00ACT0STA1DUR0001"; // off

      this.socket.emit("securtrack_command", {
        imei: imei,
        command: relayCommand,
        typeCommand: "simple_command",
        totalCommand: 1,
        currentNumber: 1,
        deviceType: deviceType,
      });

      this.$modal.hide("dialog");
    },

    sendHighTempThresholdCmd(data) {
      let { imei, temperature, deviceType } = data;

      let t = temperature.toString().padStart(3, 0);

      let highTempThresholdCmd = `#C253*00T1A1T1V+${t}CTS0TVA0MIC0SPK0`; // off

      this.socket.emit("securtrack_command", {
        imei: imei,
        command: highTempThresholdCmd,
        typeCommand: "simple_command",
        totalCommand: 1,
        currentNumber: 1,
        deviceType: deviceType,
      });

      this.$modal.hide("modal-temp-alarm");
    },

    // save/update geofence
    updateGeofenceUnit() {
      this.$refs.formEditGeofence.validate().then((success) => {
        if (!success) {
          return;
        }

        let update_units = [];
        this.units.forEach((element) => {
          update_units.push({
            _id: element._id,
            geofence_polygon: element.geofence_polygon,
          });
        });

        let update_customers = [];
        this.customers.forEach((element) => {
          update_customers.push({
            _id: element._id,
            geofence_polygon: element.geofence_polygon,
          });
        });

        let data = {
          units: update_units,
          customers: update_customers,
        };

        this.axios
          .put(`${process.env.VUE_APP_SERVER_URL}/gps/geofence/customer`, data)
          .then((response) => {
            this.handleSuccess(response.data.message);

            this.units = response.data.units;
            this.customers = response.data.customers;

            this.geofence_last_saved.units = _.cloneDeep(this.units);
            this.geofence_last_saved.customer = _.cloneDeep(this.customers);

            this.geofence_change = false;
            this.geofence_change_msg = "Alla ändringar sparades";

            this.changeCustomer();
            this.changeUnit();
          })
          .catch((error) => {
            let error_message = "Ett problem uppstod när geofence sparades";
            if (error.response && error.response.status == 400) error_message = "Otillräckliga behörigheter";
            this.handleError(error, error_message);
          });
      });
    },

    saveGeofenceEmail() {
      this.$refs.formGeofenceEmail.validate().then((success) => {
        if (!success) {
          return;
        }

        // todo: notify user when email is incorrect
        let emails = this.geofenceEmails.replace(/\s+/g, "").split(",");
        let emails_filtered = [];
        emails.forEach((e) => {
          if (this.validateEmail(e)) emails_filtered.push(e);
        });
        emails_filtered = emails_filtered.join(", ");

        let data = {
          geofence_emails: emails_filtered,
          geofence_sms: this.geofenceSMS,
        };

        let api_url = `${process.env.VUE_APP_SERVER_URL}/customer/${this.selected_customer}`;
        if (this.selected_unit != "") api_url = `${process.env.VUE_APP_SERVER_URL}/units/${this.selected_unit}`;

        this.axios
          .put(api_url, data)
          .then(() => {
            // eslint-disable-line
            this.handleSuccess("Email uppdaterad");

            if (this.selected_unit != "") {
              let unit_i = _.findIndex(this.units, { _id: this.selected_unit });
              this.units[unit_i].geofence_emails = emails_filtered;
              this.units[unit_i].geofence_sms = this.geofenceSMS;
            } else if (this.selected_customer != "") {
              let customer_i = _.findIndex(this.customers, { _id: this.selected_customer });
              this.customers[customer_i].geofence_emails = emails_filtered;
              this.customers[customer_i].geofence_sms = this.geofenceSMS;
            }

            this.hide("modal-geofence-email");
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    updateSingleGeofenceEmail() {
      this.$refs.formGeofenceSingleEmail.validate().then((success) => {
        if (!success) {
          return;
        }

        let emails = this.singleGeofeneEmails.replace(/\s+/g, "").split(",");
        let emails_filtered = [];
        emails.forEach((e) => {
          if (this.validateEmail(e)) emails_filtered.push(e);
        });
        emails_filtered = emails_filtered.join(", ");

        this.polygonPath[this.activeEmailModalIndex].email = emails_filtered;

        this.hide("single-geofence-email");
        this.checkGeofenceIfModified();
      });
    },

    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
        if (!this.infoWinOpen) this.selected_alarm_id = "";
        else this.selected_alarm_id = marker.alarmId;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
        this.selected_alarm_id = marker.alarmId;
      }
    },

    showInfoWindow(event, infoText, show) {
      if (show) {
        this.infoWindowPos = event.latLng;
        this.infoOptions.content = infoText;
        this.infoWinOpen = show;
      } else this.infoWinOpen = show;
    },

    focusAlarm(imei) {
      var vm = this;

      let markerIndex = _.findIndex(this.markers, { imei: imei });

      if (markerIndex >= 0) {
        setTimeout(() => {
          vm.$refs.map.$mapObject.panTo(vm.markers[markerIndex].position);
          vm.$refs.map.$mapObject.setZoom(18);
          // console.log("🚀 ~ file: customer.vue:1265 ~ setTimeout", vm.markers[markerIndex].position);
        }, 300);

        this.$nextTick().then(() => {
          vm.toggleInfoWindow(this.markers[markerIndex], markerIndex);
          // console.log("🚀 ~ vm.markers[markerIndex].position:", this.markers[markerIndex]);
          // console.log("🚀 ~ markerIndex:", markerIndex);
        });
      } else {
        let alarm = _.find(this.customer_alarms, { imei_number: imei });
        let gps = _.find(this.gps_alarms, { imei_number: alarm.imei_number });
        this.showSRT326({
          alarm_name: alarm.unit_name,
          battery: gps.battery_percent ? `${gps.battery_percent} %` : "-",
          gsm_signal: gps.gsm_signal ? this.getGSMSignalPercent(gps.gsm_signal) : "-",
        });
      }

      if (this.isMobile) this.mobileMenuActive = false;
    },

    showSRT326(alarm) {
      this.infoSRT326 = alarm;
      this.$modal.show("modal-info-srt326");
    },

    showTempThreshold(alarm) {
      this.infoTempThreshold = { imei_number: alarm.imei_number, unit_name: alarm.unit_name, type: alarm.type, highTemp: alarm.highTemp };
      this.$modal.show("modal-temp-alarm");
    },

    showElectCmp(alarm) {
      this.infoElectCmp = { imei_number: alarm.imei_number, unit_name: alarm.unit_name, powerConsumed: alarm.powerConsumed };
      this.$modal.show("modal-elect-cmp");
    },

    focusGeofence(path, index) {
      if (this.selectedPolygon == index) {
        this.selectedPolygon = null;
      } else {
        this.selectedPolygon = index;

        let bounds = new this.google.maps.LatLngBounds();
        path.forEach(function(pos) {
          bounds.extend(pos);
        });
        this.$refs.map.$mapObject.fitBounds(bounds);

        if (this.isMobile) this.mobileMenuActive = false;
      }

      this.editPolygon(index);
    },

    editPolygon(i) {
      if (this.isEditMode) {
        this.polygonOptions[i].editable = !this.polygonOptions[i].editable;
        this.polygonOptions[i].draggable = !this.polygonOptions[i].draggable;

        for (let j = 0; j < this.polygonOptions.length; j++) {
          if (i != j) {
            this.polygonOptions[j].editable = false;
            this.polygonOptions[j].draggable = false;
          }
        }

        if (this.polygonOptions[i].editable) {
          this.selectedPolygon = i;
        } else {
          this.selectedPolygon = null;
        }
      }
    },

    validateEmail(e) {
      //eslint-disable-next-line
      let emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      return emailRegex.test(e);
    },

    getGSMSignalPercent(g, type) {
      if (new RegExp(/^TELTONIKA/g).test(type)) {
        return g * 20 + "%";
      }
      {
        // conversion as per SRT documentation
        if (g <= 31) return Math.ceil(3.225 * g) + " %";
        else return "N/A";
      }
    },

    getNameFromUnitId(id) {
      let u = _.find(this.units, { unit_id: id });
      if (u) return u.name;
      else return "";
    },

    getNameFromUnit_id(_id) {
      let u = _.find(this.units, { _id: _id });
      if (u) return u.name;
      else return "";
    },

    getCustomerNameFromUnitId(unit_id) {
      let u = _.find(this.units, { unit_id: unit_id });
      if (u) {
        let c = _.find(this.customers, { _id: u.parent_id });
        if (c) return c.name;
        else return "";
      } else return "";
    },

    getAlarmLastSeenTime(a) {
      return {
        lastSeen: this.moment(a.updatedAt).fromNow(),
        lastSeenTime: this.moment().diff(this.moment(a.updatedAt)),
      };
    },

    async trackAlarm(imei_number, name, tracking_active, tracking_id, alarm_index) {
      try {
        if (!tracking_active) {
          // start tracking
          let gps = _.find(this.gps_alarms, { imei_number: imei_number });
          let alarm = _.find(this.customer_alarms, { imei_number: imei_number });

          let speed = parseFloat(gps.alarm_speed);
          if (alarm && new RegExp(/^SRT/g).test(alarm.type)) speed = (parseFloat(gps.alarm_speed) * 1.852).toFixed(2);

          let data = {
            position: gps.gps_pos,
            speed: speed,
          };

          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/gps/track-alarm/start/${imei_number}`, data);
          let track_alarm = response.data.track_alarm;
          let path = _.map(track_alarm.gps_path, "position");

          this.track_alarm_paths.push({
            path: path,
            imei_number: imei_number,
            infoText: `<b>Spårning - ${name}</b>`,
          });

          this.customer_alarms[alarm_index].tracking_active = true;
          this.customer_alarms[alarm_index].tracking_id = track_alarm._id;

          this.focusAlarm(gps.imei_number);

          this.handleSuccess(`Spårning startad: ${name}`);
        } else {
          // stop tracking
          let data = {
            tracking_id: tracking_id,
          };

          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/gps/track-alarm/stop/${imei_number}`, data);
          if (response.data.success) {
            this.track_alarm_paths = _.remove(this.track_alarm_paths, { imei_number: imei_number });
            this.customer_alarms[alarm_index].tracking_active = false;
            this.customer_alarms[alarm_index].tracking_id = null;

            this.handleSuccess(`Spårning avslutad: ${name}`);
          }
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    generateBounds() {
      var vm = this;
      setTimeout(() => {
        vm.$refs.map.$mapPromise.then((map) => {
          const bounds = new vm.google.maps.LatLngBounds();
          for (let m of this.markers) {
            bounds.extend(m.position);
          }
          map.fitBounds(bounds);
        });
      }, 500);
    },

    checkMobile() {
      var vm = this;

      this.isMobile = window.innerWidth < 1024;

      if (this.isMobile) {
        vm.mobileMenuActive = false;
        vm.mapFullScreenControl = false;
      } else {
        this.mobileMenuActive = true;
        this.mapFullScreenControl = true;
      }
    },

    closeMenu() {
      this.mobileMenuActive = false;
    },

    generateMobileMenu() {
      var vm = this;

      let controlDiv = this.generateGoogleMapActionDiv();
      this.mobileMenuDiv = controlDiv;

      controlDiv.addEventListener("click", () => {
        vm.mobileMenuActive = true;
      });

      vm.$refs.map.$mapPromise.then((map) => {
        map.controls[vm.google.maps.ControlPosition.TOP_RIGHT].push(this.mobileMenuDiv);
      });
    },

    show(id) {
      if (this.selected_unit != "") {
        let i = _.findIndex(this.units, { _id: this.selected_unit });
        if (i >= 0) {
          this.geofenceEmails = this.units[i].geofence_emails;
          this.geofenceSMS = _.cloneDeep(this.units[i].geofence_sms);
        }
      } else if (this.selected_customer != "") {
        let i = _.findIndex(this.customers, { _id: this.selected_customer });
        if (i >= 0) {
          this.geofenceEmails = this.customers[i].geofence_emails;
          this.geofenceSMS = _.cloneDeep(this.customers[i].geofence_sms);
        }
      }

      this.$modal.show(id);
    },

    showSingleGeofence(id, index) {
      this.activeEmailModalIndex = index;
      this.singleGeofeneEmails = this.polygonPath[index].email;
      this.$modal.show(id);
    },

    resetGeofenceAlertForm() {
      this.geofenceEmails = "";
      this.geofenceSMS = [];
      this.$refs.formGeofenceEmail.reset();
    },

    hide(id) {
      this.$modal.hide(id);
      this.resetGeofenceAlertForm();
      this.singleGeofeneEmails = "";
      this.activeEmailModalIndex = null;
    },

    addSMSInput() {
      this.geofenceSMS.push({ msisdn: "" });
    },

    removeSMSInput(i) {
      this.geofenceSMS.splice(i, 1);
    },

    // floor plan

    populateIndoorUnit() {
      this.animationRef.forEach((a) => {
        a.ref.stop();
      });
      this.animationRef = [];

      let unit = _.find(this.units, { unit_id: this.selected_indoor_unit });

      if (unit && unit.floorPlanImg) {
        this.updatefloorPlanData(unit);
        this.drawImageOnCanvas(unit.floorPlanImg);
      } else this.resetFloorPlan();
    },

    updatefloorPlanData(unit) {
      let floorPlanZones = [];
      unit.floorPlanZones.forEach((z) => {
        let tagg = _.find(this.all_unit_alarms, { modelnumber: z.tag_id });
        if (tagg) {
          let detectedAlarms = _.filter(this.indoor_alarms, { tag_id: z.tag_id });
          let alarmNumber = "";
          let zoneVisbile = this.noAlarmsTagVisible;
          let tagActive = true;

          if (detectedAlarms.length > 0) {
            alarmNumber = detectedAlarms.length.toString();
            zoneVisbile = true;
          }

          let xShift = 0.5,
            yShift = 0.9,
            fontRatio = 2;
          if (alarmNumber.length > 1) {
            xShift = 0.8;
            yShift = 0.65;
            fontRatio = 1.4;
          }

          let circle = z.circle;
          let pushBtn = _.filter(this.indoor_alarms, { tag_id: z.tag_id, pushButton: true });
          if (pushBtn.length > 0) {
            circle.fill = "#ef4444";
            circle.stroke = "rgb(239 68 68 / 35%)";
          }

          // inactive tag
          if (tagg.last_rftag_activity == null) {
            circle.fill = "#eee";
            circle.strokeWidth = 1;
            circle.stroke = "#b5b5b5";
            tagActive = false;
          } else if (this.moment().diff(this.moment(tagg.last_rftag_activity), "days") > 56) {
            circle.fill = "red";
            circle.strokeWidth = 1;
            circle.stroke = "red";
            tagActive = false;
            zoneVisbile = true;

            // stop animation
            let animIndex = _.findLastIndex(this.animationRef, { tag_id: z.tag_id });
            if (animIndex > -1) this.animationRef[animIndex].ref.stop();
          } else if (this.moment().diff(this.moment(tagg.last_rftag_activity), "days") > 28) {
            circle.fill = "orange";
            circle.strokeWidth = 1;
            circle.stroke = "#ffa501";
            tagActive = false;
            zoneVisbile = true;

            // stop animation
            let animIndex = _.findLastIndex(this.animationRef, { tag_id: z.tag_id });
            if (animIndex > -1) this.animationRef[animIndex].ref.stop();
          }

          floorPlanZones.push({
            tag_id: tagg.modelnumber,
            tag_name: tagg.unit_name,
            detectedAlarms: detectedAlarms.length,
            tagActive: tagActive,
            circle: { ...circle, visible: zoneVisbile },
            text: {
              x: z.circle.x - z.circle.radius * xShift,
              y: z.circle.y - z.circle.radius * yShift,
              text: alarmNumber,
              fill: "#FFF",
              fontFamily: "sans-serif",
              fontSize: z.circle.radius * fontRatio,
              fontStyle: "bold",
              align: "center",
              shadowColor: "black",
              shadowBlur: 10,
              shadowOffset: { x: 10, y: 10 },
              shadowOpacity: 0.5,
              visible: zoneVisbile,
            },
          });
        }
      });
      this.floorPlanZones = floorPlanZones;

      this.unit_indoor_alarms = _.filter(this.indoor_alarms, { parent_id: this.selected_indoor_unit });
    },

    drawImageOnCanvas(image) {
      var vm = this;

      var img = new Image();
      img.src = image;

      img.onload = function() {
        vm.imageSize.width = this.width;
        vm.imageSize.height = this.height;
        vm.checkScreenSizeCanvas();

        vm.configImg.image = img;
        vm.startPulseAnimation();
      };
    },

    showLabel(event, zone, show) {
      var vm = this;
      let stage = this.$refs.stage.getNode();

      if (show) {
        let alarms = _.filter(this.indoor_alarms, { tag_id: zone.tag_id });

        let point = { x: zone.circle.x, y: zone.circle.y };
        if (event) {
          point = event.target.getStage().getRelativePointerPosition();
          stage.container().style.cursor = "pointer";
        }

        let rectWidth = 0,
          rectHeight = 20;
        this.labelTextList = [];

        this.labelTextList.push({
          x: 10,
          y: 10,
          text: `${zone.tag_name}`,
          fill: "#FFF",
          fontFamily: "sans-serif",
          fontSize: 16,
          fontStyle: "bold",
        });

        for (let i = 0; i < alarms.length; i++) {
          let a = alarms[i];

          let timestamp = this.moment(a.tag_timestamp).format("YYYY-MM-DD HH:mm");
          let alarm_name = `- ${a.unit_name}, ${timestamp}`;

          this.labelTextList.push({
            x: 10,
            y: 10,
            text: alarm_name,
            fill: a.pushButton ? "red" : "#FFF",
            fontFamily: "sans-serif",
            fontSize: 16,
            align: "center",
          });
        }

        this.$nextTick().then(() => {
          for (let i = 0; i < vm.$refs.labelText.length; i++) {
            let textNode = vm.$refs.labelText[i].getNode();

            let paddingHeight = 0;
            if (i == 1 && vm.labelTextList.length > 1) paddingHeight = 15;
            if (i > 1) paddingHeight = 7;

            let textWidth = textNode.width() + 20;
            if (textWidth > rectWidth) rectWidth = textWidth;

            rectHeight += textNode.height() + paddingHeight;

            if (i > 0) {
              let prevTextNode = vm.$refs.labelText[i - 1].getNode();
              let y = prevTextNode.y() + prevTextNode.height() + paddingHeight;
              textNode.y(y);
            }
          }

          vm.configLabelRect.width = rectWidth;
          vm.configLabelRect.height = rectHeight;
        });

        this.configLabelGroup.x = point.x;
        this.configLabelGroup.y = event ? point.y + 45 : point.y;
        this.configLabelGroup.visible = true;
      } else {
        this.configLabelGroup.visible = false;
        this.configLabelRect.width = 0;
        this.configLabelRect.height = 0;
        stage.container().style.cursor = "auto";
      }
    },

    focusIndoorAlarm(alarm) {
      let zone = _.find(this.floorPlanZones, { tag_id: alarm.tag_id });
      if (zone) {
        this.zoomReset();
        this.showLabel(null, zone, true);
        if (this.isMobile) this.mobileMenuActive = false;
      }
    },

    startPulseAnimation() {
      this.animationRef.forEach((a) => {
        a.ref.stop();
      });
      for (let i = 0; i < this.floorPlanZones.length; i++) {
        if (this.floorPlanZones[i].tagActive) this.pulseCircle(i);
      }
    },

    pulseCircle(i) {
      let circle = this.$refs.circle[i].getNode();
      let strokeWidth = this.floorPlanZones[i].circle.strokeWidth;

      let animation = new Konva.Animation(function(frame) {
        let scale = Math.sin((frame.time * 2 * Math.PI) / 2000);
        circle.strokeWidth(strokeWidth * scale);
      }, circle.getLayer());

      animation.start();

      this.animationRef.push({
        ref: animation,
        tag_id: this.floorPlanZones[i].tag_id,
      });
    },

    toggleZoneVisibility() {
      this.noAlarmsTagVisible = !this.noAlarmsTagVisible;
      this.floorPlanZones.forEach((z) => {
        if (this.noAlarmsTagVisible) {
          z.circle.visible = true;
          z.text.visible = true;
        } else {
          if (z.detectedAlarms == 0 || z.tagActive == false) {
            z.circle.visible = false;
            z.text.visible = false;
          }
        }
      });
    },

    resetFloorPlan() {
      this.floorPlanZones = [];
      this.configLabelGroup.visible = false;
      this.imageSize = { width: 1, height: 1 };
      this.configStage = {
        width: 1,
        height: 1,
        scaleX: 1,
        scaleY: 1,
        draggable: true,
      };
      this.configImg.image = null;
      this.configImg.width = 1;
      this.configImg.height = 1;
      this.configLabelGroup.scaleX = 1;
      this.configLabelGroup.scaleY = 1;
      this.unit_indoor_alarms = [];
      this.animationRef.forEach((a) => {
        a.ref.stop();
      });
    },

    checkScreenSizeCanvas() {
      let canvasContainer = this.$refs.canvasContainer;

      if (canvasContainer && this.selected_indoor_unit != "") {
        let containerWidth = canvasContainer.offsetWidth;

        if (this.imageSize.width >= 1900) {
          let ratio = this.imageSize.height / this.imageSize.width;
          this.imageSize.width = 1900;
          this.imageSize.height = this.imageSize.width * ratio;
        }

        let scale = containerWidth / this.imageSize.width;

        this.configImg.width = this.imageSize.width;
        this.configImg.height = this.imageSize.height;

        this.configStage.width = this.imageSize.width * scale;
        this.configStage.height = this.imageSize.height * scale;

        this.configStage.scaleX = scale;
        this.configStage.scaleY = scale;

        if (containerWidth < 500) {
          this.configLabelGroup.scaleX = 0.4 / scale;
          this.configLabelGroup.scaleY = 0.4 / scale;
        } else {
          this.configLabelGroup.scaleX = 1 / scale;
          this.configLabelGroup.scaleY = 1 / scale;
        }

        if (this.$refs.stage) {
          var stage = this.$refs.stage.getStage();
          stage.position({ x: 0, y: 0 });
        }
      }
    },

    getId() {
      if (this.user.role == "superuser") return "-";
      else if (this.user.role == "user") return this.user.customers_id.length > 0 ? this.user.customers_id[0] : "-";
      else return "";
    },

    generateMapLink(mapType, alarmData) {
      let a = _.find(this.gps_alarms, { imei_number: alarmData.imei_number });
      return this.getMapLink(mapType, a.gps_pos);
    },

    // socket
    initSocketClient() {
      this.socket = io(process.env.VUE_APP_ALARM_SERVER, {
        auth: {
          token: this.token,
        },
      });

      this.socket.on("unauthorized", async (data) => {
        try {
          await this.refreshToken();
          this.socket.auth.token = this.token;
          if (this.token) {
            this.socket.connect();
            // retry last failed attempt
            if (data && data.length > 1) this.socket.emit(data[0], data[1]);
          }
        } catch (error) {
          this.handleError(error);
        }
      });

      this.socket.on("securtrack_command_response", (data) => {
        if (data.type == "info") this.handleSuccess(data.message);
        else if (data.type == "error") this.handleError(null, data.message);
      });

      this.socket.on("command_response", (data) => {
        if (data.code == "alarm_response") this.getGPSCustomer();
      });
    },
    handleSearch(newQuery) {
      this.searchQuery = newQuery;
      if (newQuery == "") {
        this.selected_unit = "";
      }
    },
    showSettingsModal() {
      this.$modal.show("alarm-sound-setting");
    },
    changeUnitAlarms() {
      if (this.selected_unit != "") {
        this.handleSearch(this.selected_unit);
      } else {
        this.handleSearch("");
      }
    },
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    addons() {
      return this.$store.state.addons;
    },
    token() {
      return this.$store.state.token;
    },
    google: gmapApi,
    filteredData() {
      if (!this.searchQuery) return this.customer_alarms; // If no search query, return all items

      const lowerCaseSearchQuery = this.searchQuery.toLowerCase();
      // For selecting department in dropdown. Function recieves _id, check in units for a match.
      let matchingUnits = this.units.filter(unit => 
        unit._id.toLowerCase().includes(lowerCaseSearchQuery)
      );
      // Map the matches and put them in an array.
      const matchingUnitIds = matchingUnits.map(unit => unit.unit_id);
      // If no matches, the query is most likely a user search.
      if (matchingUnitIds.length === 0) {
        return this.customer_alarms.filter((item) => {
          return item.unit_name.toLowerCase().includes(lowerCaseSearchQuery) || item.phone.includes(lowerCaseSearchQuery) || item.imei_number.includes(lowerCaseSearchQuery) || item.parent_id.includes(lowerCaseSearchQuery);
        });
      }
      // Filter custer_alarms on department unit_id.
      return this.customer_alarms.filter(item => 
        matchingUnitIds.includes(item.parent_id) 
      );
    }
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;

    this.getGPSCustomer();
    this.initSocketClient();
    window.addEventListener("resize", this.checkMobile);
  },

  mounted() {
    this.refreshGPSData();
    this.generateMobileMenu();
    this.checkMobile();

    window.addEventListener("resize", this.checkScreenSizeCanvas);
    this.$store.commit("SET_ALARM_ID", 123123);
  },

  beforeDestroy() {
    clearInterval(this.refreshIntervalRef);
    window.removeEventListener("resize", this.checkMobile);

    this.animationRef.forEach((a) => {
      a.ref.stop();
    });

    this.socket.disconnect();
    if (this.audioPlayInterval) {
      clearInterval(this.audioPlayInterval);
    }
  },
  watch: {
    "$i18n.locale"() {
      this.getGPSCustomer();
    },
  },
};
</script>
